import React, { useState, useEffect, FunctionComponent } from "react"
import "../assets/styles/components/header.scss"
import BrooiLogo from "../assets/images/Brooi-logo.svg"

const IndexPage = () => {
  return (
    <>
      <nav className="nav-wrapper">
        <header className="navigation-wrapper">
          <div className="logo--wrapper">
            <img src={BrooiLogo} alt="" />
          </div>
        </header>
      </nav>
    </>
  )
}

export default IndexPage
