import React from "react"
import "../assets/styles/components/card.scss"

const DescCard = (props: any) => {
  const { headText, subtext, image } = props
  return (
    <div className="card--wrapper">
      <div className="card--img--wrapper">
        <img src={image} alt="" />
      </div>
      <div className="text--wrappers">
        <h4 className="card--headtext">{headText}</h4>
        <p className="card--subtext">{subtext}</p>
      </div>
    </div>
  )
}

export default DescCard
