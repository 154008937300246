import React, { useState, useRef, useEffect } from "react"
import "../assets/styles/components/input.scss"
import ModalImage from "../assets/images/Modal-image.png"
import BrooiLogo from "../assets/images/Brooi-logo.svg"
import Modal from "../components/modal"
import Email from "../assets/images/Group 8855.png"
import Loader from "./loader"

const Input = ({ status, message, onValidated }: { status?: string; message?: string; onValidated?: any }): any => {
  const [email, setEmail] = useState("")
  const modalRef1: any = useRef()

  const handleChange = (e: any) => {
    setEmail(e.target.value)
  }

  ////////////////  cleaning and removing the 0 that comes with mailchimp /////////////////
  const getMessage = (message: any) => {
    if (!message) {
      return null
    }
    const result = message.split("-")
    if ("0" !== result[0].trim()) {
      return message
    }
    const formattedMessage = result[1].trim()
    return formattedMessage
  }

  //////////////////////   handling submit event ///////////////////
  const handleFormSubmit = (event: any) => {
    event.preventDefault()
    const isFormValidated = onValidated({ EMAIL: email })
    return email && isFormValidated && setEmail("")
  }

  //////////////////////   handling key event ///////////////////////
  const handleInputKeyEvent = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      handleFormSubmit(event)
    }
  }

  useEffect(() => {
    "success" === status ? modalRef1?.current.openModal() && setEmail("") : ""
  }, [status, setEmail])

  return (
    <div>
      <div className="page--input--wrapper">
        <div className="page--input--inner">
          <input
            type="email"
            name="Email"
            id="Email"
            value={email}
            placeholder="Enter your email"
            className={`email--input ${"error" === status ? "email--border" : ""}`}
            onChange={handleChange}
            onKeyUp={handleInputKeyEvent}
          />
          <button
            disabled={!email || "sending" === status ? true : false}
            className={`input--btn ${!email ? "disable " : ""} `}
            onClick={handleFormSubmit}
          >
            {"sending" === status ? <Loader /> : "Join"}
          </button>
        </div>
      </div>

      <Modal ref={modalRef1}>
        <div className="success--wrapper--modal">
          <div className="image--text--wrapper">
            <section className="opacity-filter-wrapper"></section>
            <div className="modal--main--image--inner">
              <img src={ModalImage} alt="Modal--image" />
            </div>
            <div className="modal--image--text--wrapper">
              <p className="modal--image--text">
                Find Your Next <span className="modal--text--inner">Place...</span>{" "}
              </p>
            </div>
            <div className="bottom--logo--modal">
              <img src={BrooiLogo} alt="brooi--logo" />
            </div>
          </div>
          <div className="success--wrapper--inner">
            <div className="email--image--wrapper">
              <img src={Email} alt="email--image" />
            </div>
            <div className="success--headtext">
              <h2 className="success--headtext--main">Congratulations!</h2>
            </div>
            <div className="success--subtext">
              <p className="success--subtext--text">
                You have been successfully added to our waitlist, you will be notified when we launch. Check your mail
                for more information.
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <div className="min-h-42px">{"error" === status && <p className="error--text">{getMessage(message)}</p>}</div>
    </div>
  )
}

export default Input
