import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Input from "./input"

const Mailchimp = () => {
  const MAILCHIMP_URL =
    "https://gmail.us14.list-manage.com/subscribe/post?u=7fcc7fc63f6f007fb2dd3c4c6&amp;id=bd11e87c79"
  return (
    <div>
      <MailchimpSubscribe
        url={MAILCHIMP_URL}
        render={(props: any) => {
          const { subscribe, status, message } = props
          return <Input status={status} message={message} onValidated={(formData: any) => subscribe(formData)} />
        }}
      />
    </div>
  )
}

export default Mailchimp
