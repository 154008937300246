/* eslint-disable react/display-name */

import React, { useState, forwardRef, useImperativeHandle } from "react"
import Portal from "./portal"
import "../assets/styles/components/modal.scss"

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false)
  const [clickOutsideToClose, setclickOutsideToClose] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose()
    }
  })

  ////////////////////   modal open function    ////////////////
  const handleOpen = () => {
    document.body.style.overflow = "hidden"
    setDisplay(true)
    setclickOutsideToClose(true)
  }

  /////////////////// modal close function  //////////////////
  const handleClose = () => {
    document.body.style.overflow = "auto"
    setDisplay(false)
    setclickOutsideToClose(false)
  }

  //////////////// close on click of outside ////////////

  const outsideClose = e => {
    if (clickOutsideToClose === true) {
      if (e.target.getAttribute("class") && e.target.getAttribute("class").includes("overlay--styles")) {
        handleClose()
      }
    }
  }

  const checkWindow = () => {
    if (window.loaded === true) {
      setDisplay(true)
    } else return
  }

  // eslint-disable-next-line prettier/prettier
  return (
    <Portal>
      <div
        className={`overlay--styles ${display === true ? "active" : display === false ? "inactive" : " "} `}
        onClick={outsideClose}
      >
        <div className={`modal--styles ${display === true ? "onOpen" : ""} slide-up `}>
          {props.children}
          <div className="x-mark" onClick={handleClose}>
            <svg
              viewBox="0 0 12 12"
              role="presentation"
              aria-hidden="true"
              focusable="false"
              style={{
                height: 12,
                width: 12,
                display: "block",
                fill: "currentcolor"
              }}
            >
              <path
                d="m11.5 10.5c.3.3.3.8 0 1.1s-.8.3-1.1 0l-4.4-4.5-4.5 4.5c-.3.3-.8.3-1.1 0s-.3-.8 0-1.1l4.5-4.5-4.4-4.5c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l4.4 4.5 4.5-4.5c.3-.3.8-.3 1.1 0s .3.8 0 1.1l-4.5 4.5z"
                fillRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </Portal>
  )
})

export default Modal
