//////////  imported components /////
import React from "react"
import "../assets/styles/page/index.scss"
import Footer from "../components/footer"
import Header from "../components/header"
import DescCard from "../components/card"
import SEO from "../components/seo/seo"
import Mailchimp from "../components/mailchimp"

/////////// images ////////////
import BrownBar from "../assets/images/Rectangle 209.png"
import BrooiPropertyPic from "../assets/images/house-head-img.png"
import SideImage from "../assets/images/Rectangle 210.svg"
import BrownArrow2 from "../assets/images/Vector 6.png"
import Consultation from "../assets/images/Consultation by phone.png"
import Personnel from "../assets/images/Group 8833.png"
import DataImage from "../assets/images/Data exchange_2.png"
import PersonalisedImg from "../assets/images/Group 17.png"

const IndexPage = (): any => {
  return (
    <>
      <SEO
        title={"Join the waitlist and we'll notify you when we launch - Brooi"}
        description={
          "Find your next place to live - working with real estate agents, property owners, and property developers, we provide access to multiple property listings across Africa"
        }
        image={`https://ik.imagekit.io/5xfiwxhklc/Twitter_post_-_72_-kqI_xBLp.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651571337007`}
      />
      <main className="welcome">
        <Header />
        <div className="welcome--wrapper">
          <main className="wrapper-container-main">
            <div className="main--section-container">
              <div className="main--section--inner">
                <div className="main--first--section-wrapper">
                  <div className="coming--soon--wrapper--main">
                    <div className="page--brown--bar--wrapper">
                      <img src={BrownBar} alt="brown" />
                    </div>
                    <span className="page--coming--soon--wrapper">Coming soon</span>
                  </div>
                  <div className="page--main--text--wrapper">
                    <h1 className="page--main--text--content">
                      Find your <span className="page--maintext--inner">Next Place</span>To Live.
                    </h1>
                  </div>
                  <div className="page--realestate--text--wrapper">
                    <p className="page--realestate--text">Making real estate efficient, seamless and more reliable.</p>
                  </div>
                  <Mailchimp />
                  <span className="page--join--us--subtext--wrapper">
                    <p className="page--join--us--text">Join the waitlist and get notified when we launch!</p>
                  </span>
                  <div></div>
                </div>

                <div className="main--second--section--wrapper">
                  <div className="second--section--image">
                    <img src={BrooiPropertyPic} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flx-circle">
              <div className="first-circle"></div>
              <div className="second-circle"></div>
            </div>
          </main>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 318">
            <path
              fill="#fff"
              fillOpacity="1"
              d="M0,128L60,154.7C120,181,240,235,360,256C480,277,600,267,720,234.7C840,203,960,149,1080,138.7C1200,128,1320,160,1380,176L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div className="second--section--wrapper">
          <div className="second--second--side--img">
            <img src={SideImage} alt="" />
          </div>
          <div className="second--section--main--content">
            <div className="topic--head--wrapper">
              <h1 className="section--section--main--headtext">Why Brooi?</h1>
              <div className="brown--bottom--bar"></div>
            </div>
            <p className="section--section--main--othertext">
              We started Brooi with one goal: to fix the house-hunting problem in Africa. We want to empower people with
              an efficient, seamless, and more reliable real estate experience.
            </p>

            <div className="we--offer--text--wrapper">
              <h3 className="we--offer--main--text">What we Offer:</h3>
            </div>

            <div className="four--cards--wrapper">
              <div className="four--cards--inner">
                <DescCard
                  image={PersonalisedImg}
                  headText="Personalized Services"
                  subtext="We want to help you make your dream home a reality. We personalize the process to make the best decisions about your property."
                />

                <DescCard
                  image={Personnel}
                  headText="Experienced Professionals"
                  subtext="Real estate brokers, agents, developers,  designers, and lawyers assigned to help you find the best solutions in the market."
                />

                <DescCard
                  image={Consultation}
                  headText="Dedicated Customer Support "
                  subtext="We are here to help you answer any questions you might have. We look forward to hearing from you."
                />

                <DescCard
                  image={DataImage}
                  headText="Smart and Data-driven"
                  subtext="We empower our clients by using analytics and data-driven techniques to uncover insights in data."
                />
              </div>
              <div className="brown--arrow--image--wrapper">
                <div className="brown--inner--arrow1">{/* <img src={BrownArrow} alt="" /> */}</div>
                <div className="brown--inner--arrow2">
                  <img src={BrownArrow2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="last--content">
          <div className="overlay--image">
            <div className="last--section--text--wrapper">
              <h2 className="last--section--header">
                Home is Where Your Story Begins. We Want To Help Start Your Story!
              </h2>
              <p className="last--section--subtext">
                Housing plays a foundational role in each of our lives. The house-hunting process in Africa is broken,
                we want to fix it and make it easier. Working with real estate agents, property owners, and property
                developers, we provide access to multiple property listings and we help simplify the rest of the
                house-hunting process so you can find your next place to live at a great price.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  )
}

export default IndexPage
