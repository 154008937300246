import * as React from "react"
import { Helmet } from "react-helmet"

interface SEOProps {
  description?: string
  image?: string
  lang?: string
  meta?: Array<{ name: string; content: string }>
  title: string
}

const SEO = ({ description = "", lang = "en", meta = [], title, image = "" }: SEOProps) => {
  const site = {
    siteMetadata: {
      description: "",
      title: ""
    }
  }

  const metaDescription: string = description || site?.siteMetadata.description
  const defaultTitle: string = site.siteMetadata?.title || ""

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ""}
      meta={[
        {
          name: "theme-color",
          content: "1F2A33"
        },
        {
          name: "msapplication-TileColor",
          content: "1F2A33"
        },
        {
          name: "msapplication-navbutton-color",
          content: "1F2A33"
        },
        {
          name: "apple-mobile-web-app-status-bar-style",
          content: "1F2A33"
        },
        {
          name: "description",
          content: metaDescription
        },
        {
          property: "og:title",
          content: title
        },
        {
          property: "og:locale",
          content: lang
        },
        {
          property: "og:description",
          content: metaDescription
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "628"
        },
        {
          key: "og:image",
          property: "og:image",
          content: image
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:title",
          content: title
        },
        {
          property: "twitter:description",
          content: metaDescription
        },
        {
          key: "twitter:image",
          property: "twitter:image",
          content: image
        }
      ]}
    ></Helmet>
  )
}

export default SEO
