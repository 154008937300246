import React from "react"
import "../assets/styles/components/loader.scss"

const Loader = () => {
  return (
    <div>
      <span data-testid="dot-loader" className="_l7ztla">
        <span className="_8od0vn4"></span>
        <span className="_xeg0rs5"></span>
        <span className="_1j74bfux"></span>
      </span>
    </div>
  )
}

export default Loader
